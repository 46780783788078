import React from 'react';
import BaseTemplate from '../../../components/templates/BaseLayout';
import SEO from '../../../components/atoms/utility/seo';
import Spacer from '../../../components/atoms/utility/Spacer';
import EventsListBlogV2 from '../../../components/organisms/EventsListBlogV2';
import { austinEvents } from '../../../content/events/austin-events';

function AustinFree() {
  return (
    <BaseTemplate>
      <SEO
        title={'Free Events Austin'}
        description={'Come find Free activities in Austin!'}
      />
      <Spacer sizeY={1} className="" />
      <EventsListBlogV2
        cityName="Austin"
        categoryPage={'Free'}
        eventsData={austinEvents}
      />
      <Spacer sizeY={1} className="" />
    </BaseTemplate>
  );
}

export default AustinFree;
